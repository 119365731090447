
import { computed, onMounted, reactive, ref, toRefs, watch } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { dateFormat } from '@/utils/helper/format';
import { PromotionTypeEnum } from '@/core/data/constance';

// const store = 'store';
const breadcrumbs = ['Promotions', ['Promotion List']];

export default {
  name: 'Commission List',
  components: {
    Datatable,
  },
  setup() {
    const tableRef = ref<any>(null);
    const state = reactive({
      tableData: [] as any,
      loading: false,
      total: 0,
      rowsPerPage: 10,
      currentPage: 1,
      type: 'Fix',
    });

    watch(
      () => [
        tableRef.value?.pagination.rowsPerPage,
        tableRef.value?.pagination.page,
      ],
      async ([rows, page]) => {
        state.currentPage = page;
        state.rowsPerPage = rows;

        if (state.rowsPerPage + 1 > state.total) {
          state.currentPage = 1;
        }

        await fetch(state.currentPage, state.rowsPerPage);
      }
    );

    const fetch = async (page = 1, rowsPerPage = 10) => {
      try {
        state.loading = true;
        const queryCondition: any = {
          page,
          pageSize: rowsPerPage,
          type: state.type,
        };
        const { data } = await ApiService.get(
          `/promotion?${new URLSearchParams(queryCondition)}`
        );

        if (data.status) {
          state.total = data.total;
          state.tableData = data.datas;
        }

        state.loading = false;
      } catch (error) {
        state.loading = false;
      }
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs(
        breadcrumbs[0] as string,
        breadcrumbs[1] as Array<Record<string, unknown> | string>,
        {}
      );
      await fetch();
    });

    return {
      ...toRefs(state),
      tableHeader: computed(() => {
        return [
          {
            key: 'prefixCode',
            name: 'Promotion Code',
            sortable: true,
          },
          {
            key: 'name',
            name: 'Name',
            sortable: true,
          },
          {
            key: 'isActive',
            name: 'Status',
            sortable: false,
          },
          {
            key: 'startDate',
            name: 'Start',
            sortable: false,
          },
          {
            key: 'endDate',
            name: 'End',
            sortable: false,
          },
          {
            key: 'editView',
            name: 'Edit/View',
            sortable: false,
          },
        ];
      }),
      breadcrumbs,
      tableRef,
      dateFormat,
      PromotionTypeEnum,
      async handleRadioChange(e) {
        state.type = e.target.value;

        await fetch();
      },
    };
  },
};
